'use client';

import { motion, useScroll, useTransform } from 'framer-motion';
import { CheckCircle } from 'lucide-react';
import Head from 'next/head';
import Image from 'next/image';
import { useEffect, useRef, useState } from 'react';
import { CTA } from '@/components/CTA/CTA';
import { COMPANY_ASSETS } from '@/lib/constants';
import { components } from '@/types/strapi';
import { ContactForm } from '../../ContactForm';
import MediaCarousel from '../../media-carousel/MediaCarousel';

const defaultFeatures = [
	{ text: '30% Improved Delivery Rates' },
	{ text: '20% Cost Reduction' },
	{ text: '24/7 Expert Support' },
	{ text: '99.9% Uptime Guarantee' },
];

const defaultGradient = {
	from: '#7C3AED', // purple-600
	via: '#3B82F6', // blue-500
	to: '#06B6D4', // cyan-400
};
const defaultTrustSeal = {
	url: COMPANY_ASSETS.trustSeal,
	alternativeText: 'Trust Seal',
};

export const Hero = ({
	heading = 'Welcome to Our Service',
	sub_heading = 'We provide the best solutions for your business needs.',
	background_gradient = defaultGradient,
	features = defaultFeatures,
	trust_seal = defaultTrustSeal,
	CTAs,
	form_scripts,
	hero_image,
}: components['schemas']['DynamicZoneHeroComponent']) => {
	const { scrollY } = useScroll();
	const y = useTransform(scrollY, [0, 500], [0, -150]);
	const [isClientSide, setIsClientSide] = useState(false);
	const [isMediaVisible, setIsMediaVisible] = useState(false);
	const mediaRef = useRef<HTMLDivElement>(null);
	const trustSealUrl = trust_seal?.url || '/default-trust-seal.png';

	// Detect client-side rendering
	useEffect(() => {
		setIsClientSide(true);

		// Preload trust seal image
		if (trustSealUrl && typeof window !== 'undefined') {
			const img = new window.Image();
			img.src = trustSealUrl;
		}
	}, [trustSealUrl]);

	// Use Intersection Observer to detect when media section is visible
	useEffect(() => {
		if (!mediaRef.current || isMediaVisible) return;

		const observer = new IntersectionObserver(
			entries => {
				if (entries[0].isIntersecting) {
					setIsMediaVisible(true);
					observer.disconnect();
				}
			},
			{
				threshold: 0.1,
				rootMargin: '100px', // Start loading slightly before it's visible
			},
		);

		observer.observe(mediaRef.current);

		return () => observer.disconnect();
	}, [isClientSide, isMediaVisible]);

	const gradientStyle = background_gradient
		? {
				background: `linear-gradient(to bottom right, ${background_gradient.from}, ${background_gradient.via}, ${background_gradient.to})`,
			}
		: {
				background: `linear-gradient(to bottom right, ${defaultGradient.from}, ${defaultGradient.via}, ${defaultGradient.to})`,
			};
	return (
		<>
			<Head>
				{trust_seal?.url && (
					<link
						rel="preload"
						href={trustSealUrl}
						as="image"
						type="image/png"
						fetchPriority="high"
					/>
				)}
			</Head>
			<section
				className="relative flex min-h-[90vh] items-center overflow-hidden"
				style={gradientStyle}>
				{/* Background Effects */}
				<div className="absolute inset-0 z-0">
					<div className="absolute inset-0 bg-[radial-gradient(circle_at_top_right,rgba(255,255,255,0.15),transparent_50%)]"></div>
					<div className="absolute inset-0 bg-[radial-gradient(circle_at_bottom_left,rgba(255,255,255,0.15),transparent_50%)]"></div>

					{/* Simple Wave */}
					<div className="absolute bottom-0 left-0 right-0 h-64 overflow-hidden">
						<svg
							viewBox="0 0 1200 120"
							preserveAspectRatio="none"
							className="animate-wave absolute bottom-0 h-full w-[200%]">
							<path
								d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
								className="fill-white/10"></path>
						</svg>
					</div>
				</div>

				{/* Content */}
				<motion.div
					style={{ y }}
					className="container relative z-10 mx-auto px-4 py-8"
					role="region">
					<div className="grid items-center gap-8 md:gap-12 lg:grid-cols-2 lg:gap-16">
						{/* Left Column */}
						<div className="max-w-2xl space-y-6 md:space-y-8 lg:space-y-10">
							<div className="space-y-4 md:space-y-6">
								<h1 className="text-3xl font-bold text-white sm:text-4xl md:text-5xl lg:text-6xl">
									{heading}
								</h1>
								<p className="text-lg leading-relaxed text-white/90 md:text-xl">
									{sub_heading}
								</p>
							</div>

							{/* CTA Buttons */}
							<div className="flex flex-wrap gap-4">
								<CTA CTAs={CTAs || []} />
							</div>

							{/* Features List */}
							<div className="grid grid-cols-1 gap-3 sm:grid-cols-2 md:gap-4">
								{features.map((feature, index) => (
									<div
										key={index}
										className="flex items-center gap-2 md:gap-3">
										<CheckCircle className="h-4 w-4 text-white md:h-5 md:w-5" />
										<span className="text-xs font-medium text-white sm:text-sm">
											{feature.text}
										</span>
									</div>
								))}
							</div>

							{/* Trust Seal */}
							{trust_seal?.url && (
								<div className="pt-4">
									<Image
										src={trustSealUrl}
										alt={
											trust_seal?.alternativeText ||
											'Trust Seal'
										}
										width={400}
										height={116}
										className="w-full max-w-[280px] drop-shadow-lg sm:max-w-[320px] md:max-w-[400px]"
										priority={true}
										loading="eager"
										sizes="(max-width: 640px) 280px, (max-width: 768px) 320px, 400px"
										fetchPriority="high"
										unoptimized={false}
										quality={90}
									/>
								</div>
							)}
						</div>

						{/* Right Column - Contact Form or Hero Image */}
						<div
							className="relative hidden md:block"
							ref={mediaRef}>
							{hero_image && hero_image.length > 0 ? (
								<motion.div
									initial={{ opacity: 0, scale: 0.95 }}
									animate={{ opacity: 1, scale: 1 }}
									transition={{ duration: 0.5 }}
									className="relative overflow-hidden">
									{isClientSide && isMediaVisible && (
										<MediaCarousel
											images={hero_image.map(img => ({
												url: img.url || '',
												alternativeText:
													img.alternativeText ||
													'Hero Image',
											}))}
											lazyLoad={true}
										/>
									)}
								</motion.div>
							) : (
								<ContactForm script={form_scripts} />
							)}
						</div>
					</div>
				</motion.div>
			</section>
		</>
	);
};
